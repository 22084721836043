.App {
  text-align: center;
  background: rgba(0, 0, 0, 0.5); 
  padding: 30px 20px; 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  width: 35%;
  margin: 0 auto; 
  margin-top: 140px; 
}

.welcomemsg {
  text-align: center;
  font-size: 3em;
  margin-bottom: 20px;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  background: linear-gradient(90deg, #858074, #7D6340, #3e2b19, #9E5125, #9D4120); 
  min-height: 100vh;
  padding-top: 120px; 
  color: white;
}
